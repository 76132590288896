@import url('https://fonts.googleapis.com/css2?family=Bungee+Tint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Handjet:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ysabeau+SC:wght@1..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Major+Mono+Display&family=Protest+Guerrilla&display=swap');

body, #root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}

.bungee-tint-regular {
  font-family: "Bungee Tint", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.handjet-regular {
  font-family: "Handjet", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "ELGR" 1,
    "ELSH" 2;
}

.ysabeau-sc-regular {
  font-family: "Ysabeau SC", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.courier-prime-regular {
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
}

.protest-guerrilla-regular {
  font-family: "Protest Guerrilla", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.major-mono-display-regular {
  font-family: "Major Mono Display", monospace;
  font-weight: 400;
  font-style: normal;
}
