.App {
  background-color: #222;
  display: flex;
  height: 100%;
  width: 100%;
}

.image {
  flex-basis: 30%;
  display: flex;
  justify-content: center;
}

.pfp {
  align-self: center;
  width: 80%;
  border-radius: 5%;
  animation-name: russia-border;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

@keyframes russia-border {
  0% {
    box-shadow: 0 -0.5rem 1rem #f00, 0.5rem 0 1rem #fff, -0.5rem 0 1rem #fff, 0 0.5rem 1rem #00f;
  }
  25% {
    box-shadow: 0 -0.5rem 1rem #fff, 0.5rem 0 1rem #f00, -0.5rem 0 1rem #00f, 0 0.5rem 1rem #fff;
  }
  50% {
    box-shadow: 0 -0.5rem 1rem #00f, 0.5rem 0 1rem #fff, -0.5rem 0 1rem #fff, 0 0.5rem 1rem #f00;
  }
  75% {
    box-shadow: 0 -0.5rem 1rem #fff, 0.5rem 0 1rem #00f, -0.5rem 0 1rem #f00, 0 0.5rem 1rem #fff;
  }
  100% {
    box-shadow: 0 -0.5rem 1rem #f00, 0.5rem 0 1rem #fff, -0.5rem 0 1rem #fff, 0 0.5rem 1rem #00f;
  }
}

.details {
  flex-basis: 50%;
  display: flex;
  align-items: center;
}

.bold {
  font-weight: 600;
}

.about-me {
  font-size: 3em;
  color: #bbb;
}

.name {
  font-family: "Ysabeau SC", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  color: skyblue;
}

.projects {
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  height: 50%;
}

.proj-content {
  flex-basis: 90%;
  margin: 2%;
  padding: 2%;
  background-color: #333;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.proj-name {
  flex-basis: 10%;
  font-family: "Courier Prime", monospace;
  font-size: 2em;
  font-weight: 600;
}

.proj-details {
  flex-basis: 90%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 10% 80% 10%;
}

.proj-description {
  font-size: 1.25em;
}

.proj-tags {
  grid-row: 3;
  grid-column: 1/3;
}

.proj-tag {
  padding: 2px;
  margin: 2px;
  background-color: #555;
  font-size: 0.8em;
  border-radius: 3px;
}

.proj-image {
  grid-column: 2;
  grid-row: 1/3;
  padding-top: 25%;
}

.proj-image-tag {
  /* height: 30%; */
  width: 100%;
  border-radius: 10px;
}

.proj-btn-left {
  cursor: pointer;
}

.proj-btn-right {
  cursor: pointer;
}

.proj-buttons {
  flex-basis: 10%;
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  font-size: 2em;
}

.skills {
  display: grid;
  grid-template-columns: 15em 15em;
  grid-template-rows: 4em 4em;
  column-gap: 1.5em;
  color: #fff;
}

#skill-html, .skill-html {
  font-size: 2em;
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
}

#skill-css, .skill-css {
  font-size: 2em;
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
  background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#skill-js, .skill-js {
  font-size: 2em;
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
}

.contact-me {
  display: grid;
  grid-template-columns: auto;
  color: #fff;
  font-size: 2em;
}

.contact-link {
  text-decoration: none;
  color: skyblue;
}

.buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #eee;
}

.button {
  width: fit-content;
  margin: .5em;
  padding: .5em;
  background-color: rgba(51, 51, 51, 0.5);
  border-radius: 0.5em;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
}

.active-button {
  background-color: #55f;
  width: fit-content;
  margin: .5em;
  padding: .5em;
  border-radius: 0.5em;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
}

.active-button:hover {
  background-color: #88f;
}

.button:hover {
  background-color: rgba(66, 66, 66, 1);
}

.link {
  color: blueviolet;
  cursor: pointer;
}